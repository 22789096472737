import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Card } from 'antd';
import { HashLink } from 'react-router-hash-link';

class Cost extends React.Component {
    render() {
        return (

            <div className="page-wrapper">
                 <div className="cost-bg">
          <div className="cost-bg-content">
            <h1 style={{ fontSize: "40px" }} ><FormattedMessage id="cost" defaultMessage="" /></h1>
            <h1 style={{ fontSize: "20px", paddingTop: "5px" }} ><FormattedMessage id="Contact2" defaultMessage="" /></h1>
          </div>
        </div>
                <Card style={{paddingTop: "10px"}}className="p-noIndent">
                    <div className="p-noIndent">
                        <div className="Odd">
                        <h2 style={{paddingLeft: "30px", color: "#4cc2f5"}}>
                            <FormattedMessage id="cost6" defaultMessage="" />
                           
                        </h2>
                        <p style={{paddingBottom: "20px"}} className="p-noIndent" > <FormattedMessage id="cost1" defaultMessage="" />
                        <a><FormattedMessage id="out-of-network" defaultMessage="" values={{
                                p: chunks => <p>{chunks}</p>,
                                linkEN: <a href="https://individual.carefirst.com/individuals-families/health-insurance-basics/understanding-your-coverage/in-network-vs-out-of-network.page" target="blank">< FormattedMessage  id="out-of-network-1" defaultMessage="" /></a>,
                                linkES: <a href="https://disciplied.com/fuera-de-la-red/" target="blank">< FormattedMessage  id="out-of-network-1" defaultMessage="" /></a>,
         
                                 }} /></a>
                            <FormattedMessage id=" provider" defaultMessage="" />
                         </p>
                        </div>
                        <div className="Even">
                        <h2 style={{paddingLeft: "30px", color: "#4cc2f5", paddingTop: "10px"}}>
                            <FormattedMessage id="cost7" defaultMessage="" />
                        </h2>
                        <p className="p-noIndent" > <FormattedMessage id="cost2" defaultMessage="" /></p>
                        <p className="p-noIndent" > <FormattedMessage id="cost12" defaultMessage="" /> </p>
                        </div>
                        <div className="Odd">
                        <h2 style={{paddingLeft: "30px", color: "#4cc2f5"}} >
                            <FormattedMessage id="cost8" defaultMessage="" />
                        </h2>
                        <p className="p-noIndent" ><FormattedMessage id="cost5" defaultMessage="" /></p>
                        <p style={{paddingBottom: "30px"}}className="p-noIndent" > <FormattedMessage id="cost13" defaultMessage="" /> </p>
                        </div>
                        <div className="Even">
                        <h2 style={{paddingLeft: "30px", color: "#4cc2f5", paddingTop: "10px"}}>
                            <FormattedMessage id="cost9" defaultMessage="" />
                        </h2>
                        <p className="p-noIndent" > <FormattedMessage id="cost10" defaultMessage="" /></p>
                        <p className="p-noIndent" > <FormattedMessage id="cost14" defaultMessage="" /> </p>
                        </div>
                        <div className="Odd">
                        <h2 style={{paddingLeft: "30px", color: "#4cc2f5", paddingTop: "10px"}}>
                            <FormattedMessage id="btn-Sche" defaultMessage="" />
                        </h2>
                        <p style={{ paddingBottom: "50px"}}className="p-noIndent" > <FormattedMessage id="cost3" defaultMessage="" /> <HashLink to="/Contact"><FormattedMessage id="cost4" defaultMessage="" /></HashLink> </p>
                        </div>
                    </div>
                </Card>
            </div>
        );
    }
}

export default Cost;