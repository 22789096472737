import React from "react";
import { Link, Box, Flex, Text, Button, Stack, IconButton, HStack } from "@chakra-ui/react";
import Logo from '../NFTLogo';
import { IoLogoInstagram, IoLogoLinkedin, IoLogoFacebook } from 'react-icons/io5'
import { LangSelectionContext } from '../LangSelectionContext';
import { FormattedMessage } from 'react-intl';
import Select from 'react-select'
//import { Affix, Menu, Dropdown, Button, Row, Col } from 'antd';
//<Select onChange={toggleLang}/>

const LanguageMenu = (
  <LangSelectionContext.Consumer>
    {({ currentLang, toggleLang, currentIcon }) => (
      <div>
        <Select onChange={toggleLang} />
        <img alt="lang-switch" src={currentLang === 'en-US' ? '/images/mexico.png' : '/images/USImage.png'} />
        <FormattedMessage id="btn-lang" defaultMessage="Espanol" />
      </div>
    )}
  </LangSelectionContext.Consumer>
);


const Navigation = (props) => {

  const [isOpen, setIsOpen] = React.useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <NavBarContainer {...props}>
      <Logo
        w="100px"
        color={["black", "black", "primary.500", "primary.500"]}
      />
      <MenuToggle toggle={toggle} isOpen={isOpen} />
      <MenuLinks isOpen={isOpen} />
    </NavBarContainer>
  );
};

const CloseIcon = () => (
  <svg width="24" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <title>Close</title>
    <path
      fill="black"
      d="M9.00023 7.58599L13.9502 2.63599L15.3642 4.04999L10.4142 8.99999L15.3642 13.95L13.9502 15.364L9.00023 10.414L4.05023 15.364L2.63623 13.95L7.58623 8.99999L2.63623 4.04999L4.05023 2.63599L9.00023 7.58599Z"
    />
  </svg>
);

const MenuIcon = () => (
  <svg
    width="24px"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    fill="black"
  >
    <title>Menu</title>
    <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
  </svg>
);

const MenuToggle = ({ toggle, isOpen }) => {
  return (
    <Box display={{ base: "block", md: "none" }} onClick={toggle}>
      {isOpen ? <CloseIcon /> : <MenuIcon />}
    </Box>

  );
};
const options = [

  {
    value: 'EN',
    label: <div>
      <img className='img-country' alt="english" src="/images/USImage.png" />
      English
    </div>
  },
  {
    value: 'ES',
    label: <div>
      <img className='img-country' alt="spanish" src="/images/mexico.png" />
      Spanish
    </div>
  }

]

const MenuItem = ({ children, isLast, to = "/", ...rest }) => {
  return (
    <Link href={to}>
      <Text display="block" {...rest}>
        {children}
      </Text>
    </Link>
  );
};

const MenuLinks = ({ isOpen }) => {
  return (
    <LangSelectionContext.Consumer>
      {({ currentLang, toggleLang }) => (

        <Box
          display={{ base: isOpen ? "block" : "none", md: "block" }}
          flexBasis={{ base: "100%", md: "auto" }}
        >

          <Stack
            spacing={0}
            align="center"
            justify={["center", "space-between", "flex-end", "flex-end"]}
            direction={["column", "column", "row", "row"]}
            pt={[4, 4, 0, 0]}
          >
            <MenuItem to="/home">
              <Button
                className="nav-bar-spacing"
                variant="ghost"
              >
                <FormattedMessage id="btn-home" defaultMessage="Home" />
              </Button>
            </MenuItem>

            <MenuItem to="https://www.neat.family/" >
              <Button
                className="nav-bar-spacing"
                variant="ghost"
              >
                <FormattedMessage id="btn-LP-Other" defaultMessage="Landing pg" />
              </Button>
            </MenuItem>

            <MenuItem to="/AboutUs" >
              <Button
                className="nav-bar-spacing"
                variant="ghost"
              >
                <FormattedMessage id="btn-About" defaultMessage="About Us" />
              </Button>
            </MenuItem>
            <MenuItem to="/service">
              <Button
                className="nav-bar-spacing"
                variant="ghost"
              >
                <FormattedMessage id="btn-service" defaultMessage="service" />
              </Button>
            </MenuItem>

            <MenuItem to="/Contact" >
              <Button
                className="nav-bar-spacing"
                variant="ghost"
              >
                <FormattedMessage id="btn-Sche" defaultMessage="Schedule" />
              </Button>
            </MenuItem>

            <MenuItem to="/cost" >
              <Button
                className="nav-bar-spacing"
                variant="ghost"
              >
                <FormattedMessage id="btn-cost" defaultMessage="Cost" />
              </Button>
            </MenuItem>

            <MenuItem to="https://www.therapyportal.com/p/neatfamily/" >
              <Button
                className="nav-bar-spacing"
                variant="ghost"
              >
                <FormattedMessage id="btn-MyPortal" defaultMessage="MyPortal" />
              </Button>
            </MenuItem>

            <HStack>
              <Link href='https://www.instagram.com/neatfamilytherapy/' isExternal><IconButton aria-label='Search database' icon={<IoLogoInstagram />} /></Link>
              <Link href='https://www.linkedin.com/company/neat-family-therapy/' isExternal><IconButton aria-label='Search database' icon={<IoLogoLinkedin />} /></Link>
              <Link href='https://www.facebook.com/NeatFamily' isExternal><IconButton aria-label='Search database' icon={<IoLogoFacebook />} /></Link>
            </HStack>



            <Select onChange={toggleLang} options={options} />
          </Stack>
        </Box>
      )}
    </LangSelectionContext.Consumer>
  );
};

const NavBarContainer = ({ children, ...props }) => {
  return (
    <Flex
      as="nav"
      align="center"
      justify="space-between"
      wrap="wrap"
      w="100%"
      mb={1}
      p={1}
      bg={["primary.500", "primary.500", "transparent", "transparent"]}
      color={["black", "black", "primary.700", "primary.700"]}
      {...props}
    >
      {children}
    </Flex>
  );
};
const NavBarMobileContainer = ({ children, ...props }) => {
  return (
    <Flex
      w='100vw'
      display={display}
      bgColor="gray.50"
      zIndex={20}
      h="100vh"
      pos="fixed"
      top="0"
      left="0"
      //zIndex={20}
      overflowY="auto"
      flexDir="column"
      {...props}
    >
      {children}
    </Flex>
  );
};
/*
 <Image src={currentLang === 'en-US' ? '/images/USImage.png' : '/images/mexico.png'} alt="Language flag icon" />
            
            <FormattedMessage id="btn-change" defaultMessage="lang" />
*/
export default Navigation;