import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Card, Avatar } from 'antd';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const { Meta } = Card;
/**
 *   <img src="/images/itzuri3.jpg"
         className="centered-horizontal"
       />
    */
const About = () => (
  <div className="page-wrapper">
    <div className="about-bg">
      <div className="about-bg-content">
        <h1 style={{ fontSize: "40px" }} ><FormattedMessage id="AboutUs" defaultMessage="" /></h1>
        <h1 style={{ fontSize: "20px", paddingTop: "5px" }} ><FormattedMessage id="About" defaultMessage="" /></h1>
      </div>
    </div>
    <div style={{ backgroundColor: "#f1ecec" }} >
      <Card bordered={false} style={{ paddingTop: "30px", paddingBottom: "20px", backgroundColor: "#f1ecec", textAlign: "justify", marginLeft: "10px", marginRight: "30px" }}
      >
        <Meta
          title={
            <div style={{ paddingBottom: "5px", color: "#4cc2f5" }}>
              <FormattedMessage id="Mission" defaultMessage="" />
            </div>
          }
        />
        <div>
          <ul class="custom-bullet heart" style={{  marginLeft: "30px" }}>
            <li>
              <FormattedMessage id="Mission-1" defaultMessage="" />
            </li>
          </ul>
        </div>
        <Meta
          title={
            <div style={{ paddingBottom: "5px", color: "#4cc2f5" }}>
              <FormattedMessage id="Values" defaultMessage="" />
            </div>
          }
        />
        <div>
          <ul style={{  marginLeft: "30px" }} class="custom-bullet heart">
            <li><FormattedMessage id="Values-1" defaultMessage="" /> </li>
            <li> <FormattedMessage id="Values-2" defaultMessage="" /></li>
            <li><FormattedMessage id="Values-3" defaultMessage="" /></li>
          </ul>
        </div>
        <Meta
          title={
            <div style={{ paddingBottom: "5px", color: "#4cc2f5" }}>
              <FormattedMessage id="Vision" defaultMessage="" />
            </div>
          }
        />
        <div>
          <ul style={{  marginLeft: "30px" }} class="custom-bullet heart">
            <li>
              <FormattedMessage id="Vision-1" defaultMessage="" />
            </li>
          </ul>
        </div>
      </Card>
    </div>
    <Card bordered={false} style={{ paddingTop: "30px" }}
      title={
        <div style={{ textAlign: "center", fontSize: "15px" }}>
          <h2>
            <FormattedMessage id="Our clinician" defaultMessage="Meet Our Clinician" />
          </h2>
        </div>
      }
      cover={
        <div className="profile-pic"></div>
      }>

      <Meta
        title={
          <div style={{ textAlign: "center" }}>
            Itzuri Hurtado, LMFT
          </div>
        }
        description={
          <p style={{ textAlign: "center" }}>
            California License: LMFT116055
          </p>
        }
      />
      <div >
        <p>
          < FormattedMessage id="bio-itz-p1-1" defaultMessage="" />
         </p>

        <p>
          < FormattedMessage id="bio-itz-p1" defaultMessage="" />
          <a href=""><FormattedMessage id="EMDR" defaultMessage="" values={{
            p: chunks => <p>{chunks}</p>,
            link1EN: <a href="https://www.emdria.org/" target="blank">< FormattedMessage id="EMDR-1" defaultMessage="" /></a>,
            link1ES: <a href="https://www.iemdr.es/Que-es-EMDR" target="blank">< FormattedMessage id="EMDR-1" defaultMessage="" /></a>,
          }} /></a>
          <FormattedMessage id=" and " defaultMessage="" />
          <a><FormattedMessage id="EFT" defaultMessage="" values={{
            p: chunks => <p>{chunks}</p>,
            linkEN: <a href="https://www.eftsocal.com/" target="blank">< FormattedMessage id="EFT-1" defaultMessage="" /></a>,
            linkES: <a href="https://cdn.website-editor.net/6c0bc6ed8189477e92e1c829857bcc29/files/uploaded/Programa%2520TFE.pdf" target="blank">< FormattedMessage id="EFT-1" defaultMessage="" /></a>,

          }} /></a></p>
        <FormattedMessage id="bio-itz-p1-2" defaultMessage=""
          values={{
            p: chunks => <p>{chunks}</p>
          }} />
      </div>
      <Meta
        title={
          <div style={{ paddingBottom: "5px" }}>
            <FormattedMessage id="Message" defaultMessage="" />
          </div>
        }
      />
      <div>
        <FormattedMessage id="bio-itz-p2" defaultMessage=""
          values={{
            p: chunks => <p>{chunks}</p>
          }} />
      </div>
      <Meta
        title={
          <div style={{ paddingBottom: "5px" }}>
            <FormattedMessage id="Personal Interests" defaultMessage="" />
          </div>
        }
      />
      <div>
        <FormattedMessage id="bio-itz-p3" defaultMessage=""
          values={{
            p: chunks => <p>{chunks}</p>
          }} />
      </div>
      <Meta
        title={
          <div style={{ paddingBottom: "5px" }}>
            <FormattedMessage id="Quote" defaultMessage="" />
          </div>
        }
      />
      <div>
        <FormattedMessage id="bio-itz-p4" defaultMessage=""
          values={{
            p: chunks => <p>{chunks}</p>
          }} />
      </div>
    </Card>

  </div>

);

export default About;
