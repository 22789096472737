import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import 'antd/dist/antd.css';
import { HashLink } from 'react-router-hash-link';
import People from './person Therapy.jpg';
import Children from './Child Therapy.jpg';
import Animal from './Animal Therapy.jpg';
import Family from './Family Therapy.jpg';
import Community from './Community Therapy.jpg';
import Group from './Group Therapy.jpg';
import tele from './Telehelath(1).jpg';
import Mission from './Mission.jpg';
import Couple from './Couple Therapy.jpg'

/**
 * 
 * <FormattedMessage id="Cap-7" defaultMessage="Telehealth is a form of video conferencing provided to individuals who prefer the comfort and convenience of having therapy sessions with flexible schedule." />
 *<FormattedMessage id="Cap-1" defaultMessage="Individual Therapy is a collaborative and non-judgmental process to establish needs and find solutions with the guide of an unconditional other. We are goal and insight orientated providing to our clients’ an empathetic ear, tools, and coping strategies to manage problems effectively." />
<FormattedMessage id="Cap-2" defaultMessage="Therapy for Children and Teens is fun and dynamic. We have an outstanding experience providing services to children with diverse needs including, trauma, depression, anxiety, ADHD, severe behaviors, and autism. We use Play Therapy with endless creativity to engage our clients in therapeutic work." />
<FormattedMessage id="Cap-3" defaultMessage="Animal Assisted Therapy is a therapeutic intervention using emotional support animals to help clients archive their therapeutic goals." />
<FormattedMessage id="Cap-4" defaultMessage="Community-based Therapy is a creative approach to make therapy fun and dynamic in recreational community locations." />
 <FormattedMessage id="Cap-5" defaultMessage="Family Therapy is used to help families increase their relationship and communication with family members. We understand that each family has its unique culture and we work with each family in a collaborative way." />
<FormattedMessage id="Cap-6" defaultMessage="Group Therapy is provided for individuals who need support with balancing school, work, and family. The idea is to have members reach their goals with a community based approach." />
<FormattedMessage id="Cap-8" defaultMessage=" " />

 */
const NFTCarousel = () => (
  <Carousel interval={3000} infiniteLoop={true} autoPlay transitionTime={1000} showThumbs={false}>
    <div>
      <img src={Mission} className="therapy" alt="therapy" />
      <div className="legend-bg">
        <div style={{ backgroundColor: "transparent" }} className="legend">
          <div style={{ fontSize: "30px", textAlign: "center" }}>
            <FormattedMessage id="header8" defaultMessage=" " />
          </div>
          <div style={{ fontSize: "20px", textAlign: "center" }}>
            <FormattedMessage id="header8.5" defaultMessage=" " />
          </div>
        </div>
      </div>
    </div>
    <div>
      <HashLink to="/service#TeleHealth">
        <img src={tele} className="therapy" alt="therapy" />
        <div className="legend-bg">
          <div className="legend">
            <h1>
              <FormattedMessage id="header7" defaultMessage="Telehealth" />
            </h1>

            <p className="Click">
              <FormattedMessage id="Click" defaultMessage="Click Here" />
            </p>
          </div>
        </div>
      </HashLink>
    </div>
    <div>
      <HashLink to="/service#Individual Therapy">
        <img src={People} className="therapy" alt="therapy" />
        <div className="legend">
          <h1>
            <FormattedMessage id="header1" defaultMessage="Individual Therapy" />
          </h1>

          <p className="Click">
            <FormattedMessage id="Click" defaultMessage="Click Here" />
          </p>
        </div>
      </HashLink>
    </div>
    <div>
      <HashLink to="/service#Therapy for Children">
        <img src={Children} className="Children" alt="Children" />
        <div className="legend"><h1>
          <FormattedMessage id="header2" defaultMessage="Therapy for Children and Teens" />
        </h1>

          <p className="Click">
            <FormattedMessage id="Click" defaultMessage="Click Here" />
          </p>
        </div>
      </HashLink>
    </div>
    <div>
      <HashLink to="/service#Animal Assisted Therapy">
        <img src={Animal} className="Animal" alt="Animal" />
        <div className="legend">
          <h1>
            <FormattedMessage id="header3" defaultMessage=" Animal Assisted Therapy" />
          </h1>

          <p className="Click">
            <FormattedMessage id="Click" defaultMessage="Click Here" />
          </p>
        </div>
      </HashLink>
    </div>
    <div>
      <HashLink to="/service#Community-based Therapy">
        <img src={Community} className="Community" alt="Community" />
        <div className="legend">
          <h1>
            <FormattedMessage id="header4" defaultMessage="Community-based Therapy" />
          </h1>

          <p className="Click">
            <FormattedMessage id="Click" defaultMessage="Click Here" />
          </p>
        </div>
      </HashLink>
    </div>
    <div>
      <HashLink to="/service#Family Therapy">
        <img src={Family} className="Family" alt="Family" />
        <div className="legend">
          <h1>
            <FormattedMessage id="header5" defaultMessage="Family Therapy" />
          </h1>

          <p className="Click">
            <FormattedMessage id="Click" defaultMessage="Click Here" />
          </p>
        </div>
      </HashLink>
    </div>
    <div>
      <HashLink to="/service#Group Therapy">
        <img src={Group} className="Group" alt="Group" />
        <div className="legend">
          <h1>
            <FormattedMessage id="header6" defaultMessage="Group Therapy" />
          </h1>

          <p className="Click">
            <FormattedMessage id="Click" defaultMessage="Click Here" />
          </p>
        </div>
      </HashLink>
    </div>
    <div>
      <HashLink to="/service#Couple Therapy">
        <img src={Couple} className="Couple" alt="Couple" />
        <div className="legend">
          <h1>
            <FormattedMessage id="header9" defaultMessage="Couple Therapy" />
          </h1>

          <p className="Click">
            <FormattedMessage id="Click" defaultMessage="Click Here" />
          </p>
        </div>
      </HashLink>
    </div>
  </Carousel>
);

export default NFTCarousel;



