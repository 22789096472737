import React from 'react';



export const LangSelectionContext = React.createContext(
	{
		currentLang: 'en-US',
		toggleLang: () => { },
		
	}
);