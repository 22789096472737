import React from 'react';
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from 'pure-react-carousel';

class Carousel1 extends React.Component {
  render() {
    return (
      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={125}
        totalSlides={3}
      >
        <Slider>
          <Slide index={0}></Slide>
          <Slide index={1}></Slide>
          <Slide index={2}></Slide>
        </Slider>
        <ButtonBack></ButtonBack>
        <ButtonNext></ButtonNext>
      </CarouselProvider>
    );
  }
}

export default Carousel1;