import React from 'react';
import 'antd/dist/antd.css';
import Location from '../Location';
import { Card } from 'antd';

const { Meta } = Card;



class Footeritems extends React.Component {
	constructor(props) {
		super(props);

		this.updateWidthAndHeight = () => {

			//var mode = window.innerWidth > 1150 ? 'horizontal' : 'vertical';
			this.setState(state => ({
				width: window.innerWidth,
			}));

		};

		this.toggleLang = () => {
			console.log('toggleLang called');

		};
		// State also contains the updater function so it will
		// be passed down into the context provider
		this.state = {
			width: window.innerWidth,

		};
	}
	componentDidMount() {
		window.addEventListener("resize", this.updateWidthAndHeight);
	}
	componentDidUpdate() {
		window.addEventListener("resize", this.updateWidthAndHeight);
	}
	render() {
		if (this.state.width < 800) {
			return (
				<div>
					<Card bordered={false} className='Footer-card'
					>
						<div className='Center'>
						<div style={{ fontSize: 'medium', textAlign: 'center' }} >
							<ul className="custom-bullet phone" >
								<li>
									<a href="tel:+16693079030" className='footer-link'>(669) 307-9030</a>
								</li>
							</ul>
						</div>

						<div style={{ fontSize: 'medium', textAlign: 'center' }}>
							<ul className="custom-bullet email">
							<li> <a className='footer-link' href="mailto:ihurtado@neatfamilytherapy.com?subject=Therapy Inquiry​ ">ihurtado@neatfamilytherapy.com​ </a></li>

							</ul>
						</div>

						<div style={{ fontSize: 'medium', textAlign: 'center' }}>
							<ul className="custom-bullet location">
								<li>
									<a className='footer-link' href="https://www.google.com/maps/place/830+Stewart+Dr+125+a,+Sunnyvale,+CA+94085/@37.3834881,-122.0121929,17z/data=!3m1!4b1!4m5!3m4!1s0x808fb63c214a675f:0xbd5bef7ba20e3f71!8m2!3d37.3834881!4d-122.009618?entry=ttu">830 Stewart Drive, suite 125 A, Sunnyvale, CA 94085</a>
								</li>
							</ul>
						</div>
						</div>
					</Card>
					<Location />
				</div>
			);
		}
		return (
			<div style={{ marginLeft: 'auto', marginRight: 'auto' }} className='rowC'>

				<Card bordered={false} className='Footer-card'
				>

					<div style={{ fontSize: 'medium', textAlign: 'center' }}>
						<ul className="custom-bullet phone" >
							<li>
								<a className='footer-link' href="tel:+16693079030">(669) 307-9030</a>
							</li>
						</ul>
					</div>

					<div style={{ fontSize: 'medium', textAlign: 'center' }}>
						<ul className="custom-bullet email">
						<li> <a className='footer-link' href="mailto:ihurtado@neatfamilytherapy.com?subject=Therapy Inquiry​ ">ihurtado@neatfamilytherapy.com​ </a></li>

						</ul>
					</div>

					<div style={{ fontSize: 'medium', textAlign: 'center' }}>
						<ul className="custom-bullet location">
							<li>
								<a className='footer-link' href="https://www.google.com/maps/place/830+Stewart+Dr+125+a,+Sunnyvale,+CA+94085/@37.3834881,-122.0121929,17z/data=!3m1!4b1!4m5!3m4!1s0x808fb63c214a675f:0xbd5bef7ba20e3f71!8m2!3d37.3834881!4d-122.009618?entry=ttu">830 Stewart Drive, suite 125 A, Sunnyvale, CA 94085</a>
							</li>
						</ul>
					</div>
				</Card>
				<Location />

			</div>

		);
	}
};

/**
 * <Meta
						title={
							<div style={{ paddingBottom: "5px", color: "#4cc2f5" }}>
								Call
							</div>
						}
					/>
 * <Meta
						title={
							<div style={{ paddingBottom: "5px", color: "#4cc2f5" }}>
								Email
							</div>
						}
					/>

 * <Meta
						title={
							<div style={{ paddingBottom: "5px", color: "#4cc2f5" }}>
								Location
							</div>
						}
					/>
 */
export default Footeritems;