import React from 'react';
import ReactPlayer from 'react-player'
import 'antd/dist/antd.css';

class NFTVideo extends React.Component {

  constructor(props) {
    super(props);

    this.updateWidthAndHeight = () => {

      //var mode = window.innerWidth > 1150 ? 'horizontal' : 'vertical';
      this.setState(state => ({
        width: window.innerWidth,
      }));

    };
    // State also contains the updater function so it will
    // be passed down into the context provider
    this.state = {
      width: window.innerWidth,

    };

  }
  //className="ant-btn-appoinment"
  //className='testingCalc'
  componentDidMount() {
    window.addEventListener("resize", this.updateWidthAndHeight);
  }
  componentDidUpdate() {
    window.addEventListener("resize", this.updateWidthAndHeight);
  }

  render() {
    if (this.state.width < 1065) {
      return (
        <div className='container-iframe'>
          <ReactPlayer
              className='responsive-iframe'
              controls
              width='100%'
              height='100%'
              url='https://player.vimeo.com/video/811766672'
              style={{ float: "top" }}
             />
          </div>

      );
    }
    
      return (
        <div  className="root">
          <div className="divRoot">
          <ReactPlayer
             width='100%'
             height='100%'
              url='https://player.vimeo.com/video/811766672'
              controls
             
             />
          </div>
        </div>
      );
    
  }
};
/**
 * 
 * <div  className="root">
          <div className="divRoot">
          <ReactPlayer
              className='responsive-iframe'
              width='100%'
              height='100%'
              url='https://player.vimeo.com/video/811766672'
              style={{ float: "top" }}
             />
          </div>
        </div>
 * 
 * return (
    <iframe width="100%" height="100%"
                           src="https://player.vimeo.com/video/811766672"
                           frameBorder="0"
                           allow="autoplay; fullscreen; picture-in-picture"
                           allowFullScreen
                           title="test player iframe vimeo" 
                           style={{float: "top"}}/>
    <div className="root">
      <div className="divRoot">
        <IframePlayer id={"iframe-vimeo-player-test"} iFrame={iFrame} />
      </div>
    </div>;
    );
 */
//https://player.vimeo.com/video/811766672
export default NFTVideo;