import React from 'react';
import { ConfigProvider, Layout, Image, Card } from 'antd';
import { ChakraProvider } from '@chakra-ui/react'
import { IntlProvider, FormattedMessage } from "react-intl";
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { HomeOutlined, PhoneOutlined, MoneyCollectOutlined, TeamOutlined, CloseOutlined, MenuOutlined, LoginOutlined } from '@ant-design/icons';
import ContactForm from './Form'
const { Meta } = Card;


import Footeritems from './Footer';
import Location from './Location';
import About from './About';
import Home from './Home';
import Contact from './Contact';
import Cost from './Cost';
import Navigation from './Navigation';
import Service from './Service';

import { LangSelectionContext } from './LangSelectionContext';

//import 'antd/dist/antd.css';
import './NFTApp.less';

import messages_es from "../translations/es.json";
import messages_en from "../translations/en.json";
import esES from 'antd/lib/locale-provider/es_ES';
import enUS from 'antd/lib/locale-provider/en_US';

//import LandingPage from './landingPage';


/**
 * <Image
											src="/images/nftlogo.png"
											className="logo"
											alt="Neat Family Therapy Network, Inc."
											preview={false}
										/>

 * <div className="ant-card ant-card-bordered" >
					<div className="contact-form-wrapper" >
													<ContactForm />
												</div>
											</div>

											<div style={{ paddingTop: "10vmin" }}>

											<div className="ant-card ant-card-bordered" >
												<div className="contact-form-wrapper" >
													<h2 style={{ paddingTop: 20 + 'px' }} ><FormattedMessage id='Ct-h2' defaultMessage="Submit Inquiry" /></h2>
													<h3><FormattedMessage id='Ct-h3' defaultMessage="Please enter your inquiry details below:" /></h3>
													<ContactForm />

												</div>
											</div>
										</div>
 * 
 */
const { Header, Content, Footer } = Layout;

const messages = {
	'es-ES': {
		'custom': messages_es,
		'antd': esES
	},
	'en-US': {
		'custom': messages_en,
		'antd': enUS
	}
};

const Failure = () => (
	<div>

		<h2> <FormattedMessage id="fail4" defaultMessage="" /></h2>
		<h3> <FormattedMessage id="fail1" defaultMessage="" /></h3>
		<p><FormattedMessage id="fail2" defaultMessage="" /> <a href="mailto:info@neatfamilytherapy.com">info@neatfamilytherapy.com</a> <FormattedMessage id="fail3" defaultMessage="" /> </p>

	</div>
);

const Success = () => (
	<div>
		<h2> <FormattedMessage id="success3" defaultMessage="" /></h2>
		<h3> <FormattedMessage id="success1" defaultMessage="" /></h3>
		<p> <FormattedMessage id="success2" defaultMessage="" /> <a href="mailto:info@neatfamilytherapy.com">info@neatfamilytherapy.com</a>.</p>
	</div>
);


/**
 * controls the languange and wraps around the whole app
 * 
 */

class NFTApp extends React.Component {
	constructor(props) {
		super(props);

		this.updateWidthAndHeight = () => {

			//var mode = window.innerWidth > 1150 ? 'horizontal' : 'vertical';
			this.setState(state => ({
				width: window.innerWidth,
			}));

		};

		this.toggleLang = () => {
			console.log('toggleLang called');

			this.setState(state => ({

				currentIcon:
					state.currentIcon === 'en-US'
						? HomeOutlined
						: TeamOutlined,

				currentLang:
					state.currentLang === 'en-US'
						? 'es-ES'
						: 'en-US',
			}));
		};
		// State also contains the updater function so it will
		// be passed down into the context provider
		this.state = {
			currentLang: 'en-US',
			width: window.innerWidth,
			showMenu: false,
			toggleLang: this.toggleLang,
			currentIcon: HomeOutlined,
		};
	}
	handleClick = () => {
		this.setState({ showMenu: !this.state.showMenu });
	};
	handleClickWeb = () => {
		window.location.href = "https://www.therapyportal.com/p/neatfamily/";
	};
	componentDidMount() {
		window.addEventListener("resize", this.updateWidthAndHeight);
	}
	componentDidUpdate() {
		window.addEventListener("resize", this.updateWidthAndHeight);
	}
	render() {

		if (this.state.width < 800) {
			return (
				<ChakraProvider>
				<LangSelectionContext.Provider value={this.state}>
					<LangSelectionContext.Consumer>
						{({ currentLang, toggleLang }) => (
							<ConfigProvider locale={messages[currentLang].antd}>
								<IntlProvider locale={currentLang} messages={messages[currentLang].custom}>
									<Router>
										<Layout className="layout">
										
											
												<Navigation />
											
											<Content>
												<Switch>
													<Route exact path="/" component={Home} />
													<Route path="/home" component={Home} />
													<Route path="/aboutus" component={About} />
													<Route path="/contact" component={Contact} />
													<Route path="/cost" component={Cost} />
													<Route path="/service" component={Service} />
													<Route path="/success" component={Success} />
													<Route path="/failure" component={Failure} />
												</Switch>
											</Content>
											<Footeritems/>

											<Footer style={{ textAlign: 'center' }}>Neat Family Therapy Network, Inc. ©2023</Footer>
										</Layout>
									</Router>
								</IntlProvider>
							</ConfigProvider>
						)}
					</LangSelectionContext.Consumer>
				</LangSelectionContext.Provider>
				</ChakraProvider>
			);
		}
		return (
			<ChakraProvider>
			<LangSelectionContext.Provider value={this.state}>
				<LangSelectionContext.Consumer>
					{({ currentLang, toggleLang }) => (
						<ConfigProvider locale={messages[currentLang].antd}>
							<IntlProvider locale={currentLang} messages={messages[currentLang].custom}>
								<Router>
									<Layout className="layout">
										
										
											<Navigation />
										
										<Content>
											<Switch>
												<Route exact path="/" component={Home} />
												<Route path="/home" component={Home} />
												<Route path="/aboutus" component={About} />
												<Route path="/contact" component={Contact} />
												<Route path="/cost" component={Cost} />
												<Route path="/service" component={Service} />
												<Route path="/success" component={Success} />
												<Route path="/failure" component={Failure} />
											</Switch>
										</Content>
										<Footeritems/>

									<Footer style={{ textAlign: 'center' }}>Neat Family Therapy Network, Inc. ©2023</Footer>
								</Layout>
							</Router>
						</IntlProvider>
						</ConfigProvider>
					)}
			</LangSelectionContext.Consumer>
			</LangSelectionContext.Provider >
			</ChakraProvider>
		);
	}
};

NFTApp.contextType = LangSelectionContext;

export default NFTApp;
